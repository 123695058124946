import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import { MarginExcluder } from "../components/styled-components/structures";
import { Heading, Opening } from "../components/styled-components/elements";
import TemplateWrapper from "../layouts";

export const UpdateTemplate = ({
  content,
  contentComponent,
  description,
  title,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <TemplateWrapper>
      <MarginExcluder>
        <Helmet title={`${title} | Update`} />
        <Heading>{title}</Heading>
        <Opening>{description}</Opening>
        <PostContent content={content} />
      </MarginExcluder>
    </TemplateWrapper>
  );
};

UpdateTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const Update = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <UpdateTemplate
      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      title={post.frontmatter.title}
    />
  );
};

Update.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Update;

export const pageQuery = graphql`
  query UpdateByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
      }
    }
  }
`;
